export const MAIN_COLOR = "#01FFCD";
export const WHITE_COLOR = '#FFFFFF';
export const GRAY_COLOR = '#F2F5FA';
export const PINK_COLOR = '#F05296';
export const BLUE_COLOR = "#0A6DFF";
export const ORANGE_COLOR= "#E9FDB6";
export const SECONDARY_COLOR = "#111216";
export const SECONDARY_WHITE_COLOR = "#FFFFFF";
export const NO_ACTIVE_BG_COLOR = '#D3D3D3';
export const NO_ACTIVE_COLOR = '#909090';

export const APP_FONT =  '"Borna-Regular", arial, sans-serif';