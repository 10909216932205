
import React, { useEffect } from "react";
import { required, confirmation, format, combine } from "redux-form-validators";
import Form from "react-bootstrap/Form";
import { compose } from "redux";
import {toast} from "react-toastify";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import LumenazaIcon from "../../images/lumenaza_logo_white.svg";
import { Container, Row, Col } from "react-bootstrap";
import { SECONDARY_WHITE_COLOR, MAIN_COLOR } from "../../constants/css";
import { FIELD_REQUIRED_ERROR, PASSWORDS_DIFFERENT_ERROR, PASSWORD_INVALID_ERROR, POD_NAME } from "../../constants";
import Button from "../common/styles/Button";
import { ImageCol, StartPageHeading, StartPageSubHeading, StartPageContentCol, ButtonWrapper, LumenazaLogo, StartPageBackgroundGradient } from "../common/styles/StartPages";
import InputWrapper from "../common/forms/InputWrapper";
import { checkUIDTokenData, resetUserPassword } from "../../api/calls";
import {PASSWORD_VALID_REGEX} from "../../utils/validationUtils";
import PasswordCheckList from "./PasswordChecklist";

const passwordSetMessage = "Das Passwort wurde erfolgreich gespeichert";
const passwordSetErrorMessage = "Dass Passwort konnte nicht ubernommen werden";

function PasswordForm(props) {
  
  function onSubmit(formProps) {
    const { uid, token } = props.match.params;
    const successCallback = () => {toast(passwordSetMessage, {type: "success"}); props.history.push("/login/") };
    const errCallback = () => { toast(passwordSetErrorMessage, {type: "error"}); };
    resetUserPassword(formProps, uid, token, successCallback, errCallback);
  }

  const { handleSubmit } = props;

  return (
    <Container fluid>
      <StartPageBackgroundGradient>
        <ImageCol lg={5}>
          <LumenazaLogo src={LumenazaIcon} />
        </ImageCol>
        <StartPageContentCol lg={7}>
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <StartPageHeading>{POD_NAME}</StartPageHeading>
                <StartPageSubHeading>Passwort festlegen</StartPageSubHeading>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Field
                    name="new_password"
                    component={InputWrapper}
                    type="password"
                    labelText="Passwort"
                    colorLabel={SECONDARY_WHITE_COLOR}
                    opacityLabelSmall="0.8"
                    colorBorder={SECONDARY_WHITE_COLOR}
                    colorText={SECONDARY_WHITE_COLOR}
                    className="mb-4 pb-2"
                    validate={combine(required({msg: FIELD_REQUIRED_ERROR}), format({ with: PASSWORD_VALID_REGEX, msg: PASSWORD_INVALID_ERROR }))}
                  />
                  <Field
                    name="repeat_new_password"
                    component={InputWrapper}
                    type="password"
                    labelText="Passwort erneut eingeben"
                    colorLabel={SECONDARY_WHITE_COLOR}
                    opacityLabelSmall="0.8"
                    colorBorder={SECONDARY_WHITE_COLOR}
                    colorText={SECONDARY_WHITE_COLOR}
                    className="mb-4 pb-4"
                    autoComplete="none"
                    validate={[required({ msg: FIELD_REQUIRED_ERROR }), confirmation({ field: "new_password", fieldLabel: "Passwort", msg: PASSWORDS_DIFFERENT_ERROR })]}
                  />
                  <PasswordCheckList 
                    value={props.selectedNewPassword} 
                    defaultColor={SECONDARY_WHITE_COLOR}
                    passedColor={MAIN_COLOR}
                  />
                  <ButtonWrapper>
                    <Button
                      type="submit"
                      color={SECONDARY_WHITE_COLOR}
                      colorText={SECONDARY_WHITE_COLOR}
                    >
                      bestätigen
                    </Button>
                  </ButtonWrapper>
                </Form>
              </Col>
            </Row>
          </Container>
        </StartPageContentCol>
      </StartPageBackgroundGradient>
    </Container>
  )
}


export function PasswordResetForm(props) {
  
  function checkUUIDTokenRequest(uid, token) {
    const errCallback = () => { props.history.push("/login/") };
    return checkUIDTokenData(uid, token, errCallback);
  }
  
  const { uid, token } = props.match.params;
  useEffect(() => {
    checkUUIDTokenRequest(uid, token, props);
  }, []);

  return (
    <PasswordForm {...props}></PasswordForm>
  )

};

const selector = formValueSelector("passwordReset");

function mapStateToProps(state) {
  const selectedNewPassword = selector(state, "new_password");
  return Object.assign({}, {
    selectedNewPassword
  });

}

export default compose(
  connect(mapStateToProps),
  reduxForm({ form: "passwordReset" }),
)(PasswordResetForm)