import React from 'react';
import HeaderInfo from '../common/lib/HeaderInfo';
import moment from 'moment';
import _ from 'lodash';
import { WHITE_COLOR, PINK_COLOR } from '../../constants/css';
import {ReactComponent as Attention} from '../../images/icons/warning-sign.svg';
import ContentModal from '../common/ContentModal';

class StatusBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentModal: false,
            modalTitle: '',
            modalActionLabel: '',
            modalAction: null,
        };
    }

    formatUpdated(date){
        if(date==null){
          return '-'
        }else{
          return moment(date).format('DD.MM.YYYY | hh:mm') + ' Uhr';
        }
    }

    formatBegin(date){
        if(date==null){
          return '-'
        }else{
          return moment(date).format('DD.MM.YYYY');
        }
    }

    handleCloseClick(evt) {
        evt.stopPropagation();
        this.setState({
            contentModal: true,
        });
    }
    
    resetModalState(){
        this.setState({
            contentModal: false,
        });
    }

    render() {
        const creation =_.get(this.props.definition.data,'creation_date', null);
        const updated =_.get(this.props.definition.data,'updated_date', null) || this.props.editorLastUpdate;

        return(
            <>
                {this.state.contentModal && 
                    <ContentModal
                        {...this.props}
                        modalQuestion='Sind Sie sicher, dass Sie die Eingabe beenden wollen? Nicht gespeicherte Zwischenstände gehen verloren.'
                        acceptAction={this.props.acceptAction}
                        cancelAction={this.props.cancelAction || this.resetModalState.bind(this)}
                        onHide={() => this.resetModalState()}
                        modalIcon={<Attention />}
                        getModalTitle={() => 'Wollen Sie wirklich schliessen?'}
                        colorFill={PINK_COLOR}
                        colorFillIcon={WHITE_COLOR}
                        color={PINK_COLOR}
                        cancelLabel={this.props.cancelLabel}
                        acceptLabel={this.props.acceptLabel}
                        acceptButtonColor={PINK_COLOR}
                        acceptButtonColorText={WHITE_COLOR}
                        buttonWidth={this.props.buttonWidth}
                    />
                }
                <HeaderInfo 
                    begin={this.formatBegin(creation)} 
                    lastSave={this.formatUpdated(updated)}
                    onClick={this.handleCloseClick.bind(this)}
                    background={this.props.background}
                    colorText={this.props.colorText}
                    isConfigurator={this.props.isConfigurator}
                    hoverColor={this.props.hoverColor}
                />
            </>
        )
    }
}

export default StatusBar
