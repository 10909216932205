import React from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { reduxForm, Field, getFormInitialValues, formValueSelector } from "redux-form";
import FormNavigation from '../../common/FormNavigation';
import ProductDocumentEmailsItemsContainer, {
    EmailListItem,
    DocumentListItem
} from '../../common/ProductDocumentEmailsItemsContainer';
import { StyledExplanation } from "../../common/styles/FormStyles";
import styled from "styled-components";
import { APP_FONT, SECONDARY_COLOR, SECONDARY_WHITE_COLOR, WHITE_COLOR } from "../../../constants/css"
import { WrapperTextIcon } from '../../common/styles/DocumentForm';
import { 
    EMAILS_DOCS_ITEMS_CONSUMER, 
    EMAILS_DOCS_ITEMS_CONSUMER_SPOT, 
    EMAILS_DOCS_ITEMS_PRODUCER, 
    EMAILS_DOCS_ITEMS_PRODUCER_DISTRIBUTION, 
    OTHER_CUCOS_CONSUMER, 
    OTHER_CUCOS_CONSUMER_SPOT, 
    OTHER_CUCOS_PRODUCER, 
    PAYMENT_METHOD_CHANGE_CONSUMER, 
    OTHER_CUCOS_CONSUMER_RLM } from '../../../constants/cucoConstants';

const StyledLegend = styled.legend`
    background-color: ${SECONDARY_COLOR};
    color:  ${SECONDARY_WHITE_COLOR};
    font-family: ${APP_FONT};
    font-size: 18px;
    padding: 8px 12px;
    font-weight: bold;
    margin: 0 !important;
    letter-spacing: 0.54px;
`;

const StyledRow = styled(Row)`
    background: ${WHITE_COLOR};
    margin: 0 0 80px 0 !important;
`;

const StyledCol = styled(Col)`
    padding: 20px 70px 10px 20px;

    &:last-of-type {
        padding-right: 20px;
    }
`;

const StyledPlaceholderItem = styled(WrapperTextIcon)`
    color: ${WHITE_COLOR}
`;

export const determinePaymentMethodChange = (paymentMethods, otherCucos) => {
    if (!_.includes(paymentMethods, 'direct_debit') || paymentMethods.length === 1) {
        return _.filter(otherCucos, function (o) { return o.cuco !== PAYMENT_METHOD_CHANGE_CONSUMER; })
    } else {
        return otherCucos
    }
}

export const renderOtherCucosConsumer = (paymentMethods, isSpot, isRlm = false) => {
    var otherCucos = [];
    if (isRlm) {
        otherCucos = OTHER_CUCOS_CONSUMER_RLM
    }else{
        otherCucos = isSpot ? OTHER_CUCOS_CONSUMER_SPOT : OTHER_CUCOS_CONSUMER
    }
    
    const otherCucosModified = determinePaymentMethodChange(paymentMethods, otherCucos)
    return otherCucosModified
}

export const getEmailsDocsConsumer = (isSpot) => {
    return isSpot
        ? EMAILS_DOCS_ITEMS_CONSUMER_SPOT
        : EMAILS_DOCS_ITEMS_CONSUMER
}

export const getEmailsDocsProducer = (isDistribution) => {
    return isDistribution
        ? EMAILS_DOCS_ITEMS_PRODUCER_DISTRIBUTION
        : EMAILS_DOCS_ITEMS_PRODUCER
}

export class ProductDocumentsEmailsForm extends React.Component {
    state = {
        redirect: false,
        redirectTo: null,
        activeCucoId: null,
        activeCucoContext: null,
        activeCucoType: null
    };

    validateItemIsActive(prefix, id) {
        return _.find(this.props.addedValues, { "id": `${prefix}_${id}` }) !== undefined;
    }

    addedItemHandler(cucoId, prefix, data = {}) {
        const path = this.props.location.pathname;
        const isProducer = this.props.productType === 'producer';
        const baseUrl = `${path}/sections/${prefix}_${cucoId}/?ct=${prefix}&ci=${cucoId}&pt=${this.props.productType}`;
        const cpmValue = isProducer ? null : this.props.consumptionPriceModel;
        const completeUrl = baseUrl + `&cpm=${cpmValue}`
        const activeCucoContext = _.get(data, 'context', '{}');
        this.setState({ redirect: true, redirectTo: completeUrl, activeCucoId: cucoId, activeCucoType: prefix, activeCucoContext });
    }

    getCucoData(item, prefix, key) {
        const id = _.get(item, key)
        return _.find(this.props.addedValues, { id: `${prefix}_${id}` });
    }

    renderSideItems(items) {
        return items.map(item => {
            const isEmail = _.has(item, 'email');
            const isDocument = _.has(item, 'document');
            const isPlaceholder = _.has(item, 'placeholder');
            // ToDo split component in DocumentItem and EmailItem 
            return (
                <ProductDocumentEmailsItemsContainer
                    label={item.label}
                    key={item.label}
                    isActive={this.validateItemIsActive('et', item.email)}
                    cucoData={this.getCucoData(item, 'et', 'email')}
                >
                    {isEmail &&
                        <EmailListItem
                            cucoId={item.email}
                            cucoData={this.getCucoData(item, 'et', 'email')}
                            label={item.label}
                            isActive={this.validateItemIsActive('et', item.email)}
                            onAddClick={this.addedItemHandler.bind(this)}
                        />
                    }
                    {isDocument &&
                        <DocumentListItem
                            cucoId={item.document}
                            cucoData={this.getCucoData(item, 'dt', 'document')}
                            label={item.label}
                            isActive={this.validateItemIsActive('dt', item.document)}
                            onAddClick={this.addedItemHandler.bind(this)}
                        />
                    }
                    {isPlaceholder && <StyledPlaceholderItem>Placeholder</StyledPlaceholderItem>}
                </ProductDocumentEmailsItemsContainer>
            );
        });
    }

    renderSection(items, label) {
        const isEven = items.length % 2 == 0;
        const half = isEven ? items.length / 2 : Math.ceil(items.length / 2);
        const secondHalf = items.length - half;
        let leftItems = _.cloneDeep(items.slice(0, half));
        let rightItems = _.cloneDeep(items.slice(half, items.length + 1));

        const { redirect, redirectTo, activeCucoContext } = this.state;
        const { isProducer } = this.props;

        /* Rows of left and right table should always have same height. That's not the case,
        if there's no PDF for a row. That's why we check with the following for-loop
        in which row no PDF is displayed and add a placeholder for the next step */

        const it = Math.min(half, secondHalf);
        for (let i = 0; i < it; i++) {
            if (leftItems[i].document) {
                if (rightItems[i].document === undefined) {
                    rightItems[i].placeholder = true;
                }
            }

            if (rightItems[i].document) {
                if (leftItems[i].document === undefined) {
                    leftItems[i].placeholder = true;
                }
            }
        }

        if (redirect) {
            return (
                <Redirect
                    push
                    to={{
                        pathname: redirectTo,
                        search: "",
                        state: {
                            cucoData: activeCucoContext,
                            customerApproach: this.props.approach,
                            paymentMethods: this.props.paymentMethods,
                            isProducer
                        }
                    }}
                />
            )
        }
        return (
            <Col lg={12} md={12}>
                <fieldset>
                    <StyledLegend>{label}</StyledLegend>
                    <StyledRow>
                        <StyledCol md={6} lg={6}>
                            {this.renderSideItems(leftItems)}
                        </StyledCol>
                        <StyledCol md={6} lg={6}>
                            {this.renderSideItems(rightItems)}
                        </StyledCol>
                    </StyledRow>
                </fieldset>
            </Col>
        );
    }

    renderHiddenValues() {
        return this.props.addedValues.map(item => {
            return (
                <Field
                    component="input"
                    name={item.id}
                    type="hidden"
                    style={{ height: 0 }}
                    value={item.context}
                    key={item.id}
                />)
        })
    }

    render() {
        const { isProducer, paymentMethods, consumptionPriceModel, containsDistributionCost } = this.props;
        const isSpot = consumptionPriceModel === 'spot'
        const EmailsDocsItems = isProducer
            ? getEmailsDocsProducer(containsDistributionCost)
            : getEmailsDocsConsumer(isSpot)
        const OtherCucos = isProducer
            ? OTHER_CUCOS_PRODUCER
            : renderOtherCucosConsumer(paymentMethods, isSpot, this.props.isRlm)

        return (
            // handleSubmit needed for local validation of fields
            <Form onSubmit={this.props.handleSubmit(() => undefined)}>
                <StyledExplanation>
                    Hier können Sie PDF Dokumente, wie Rechnungen und
                    Lieferbestätigung, sowie die dazugehörigen Kundenmails anlegen und
                    bearbeiten.
                </StyledExplanation>
                <Row>
                    {this.renderSection(EmailsDocsItems, "RECHNUNGSDOKUMENTE")}
                    {this.renderSection(OtherCucos, "WEITERE KUNDENKOMMUNIKATION")}
                    {this.renderHiddenValues()}
                </Row>
                <FormNavigation
                    onSaveHandler={this.props.onSaveProductOffer}
                    onPreviousPageHandler={this.props.onPreviousPage}
                    nextTabKey={this.props.nextTabKey}
                    previousTabKey={this.props.previousTabKey}
                />
            </Form>
        );
    }
}


const selector = formValueSelector('productDefinitionForm');

export default compose(
    connect(state => {
        const initialValues = getFormInitialValues('productDefinitionForm')(state);
        const productType = _.get(initialValues, 'product_category', 'slp')
        const approach = selector(state, 'salut_definition_salutation_text_is_formal');
        const paymentMethods = _.get(state, 'salespartner.data.payment_method', [])
        // const consumptionPriceModel = _.get(state, 'consumption_price_model', 'single_step')
        const consumptionPriceModel = _.get(state, 'form.productDefinitionForm.values.consumption_price_model', null);
        const isRlm = productType === "rlm";

        //TODO modify this, use the email types and document types from instance
        const fieldValues = [
            ..._.get(state, 'productOffers.definition.data.product_offer_characteristics.emails.email_types', []).map(item => {
                return { type: 'et', id: `et_${item.name}`, ..._.pickBy(item, (v, k) => { return k !== 'context_variables' }), context: item.context_variables }
            }),
            ..._.get(state, 'productOffers.definition.data.product_offer_characteristics.documents.document_types', []).map(item => {
                return { type: 'dt', id: `dt_${item.name}`, ..._.pickBy(item, (v, k) => { return k !== 'context_variables' }), context: item.context_variables }
            }),
        ]

        const plants = selector(state, 'supported_plant_types')
        let containsDistributionCost = false
        if (plants) {
            containsDistributionCost = plants.some(plant => {
                return plant.service_fees.some(fee => fee.classification === "distribution_cost_fee_producer")
            })
        }

        return {
            addedValues: fieldValues,
            productType,
            approach,
            paymentMethods,
            consumptionPriceModel,
            initialValues: state.productOffers.definition.formData,
            containsDistributionCost,
            isRlm
        }
    }),
    reduxForm({
        form: "productDefinitionForm",
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        enableReinitialize: true,
        touchOnChange: true
    }),
    withRouter
)(ProductDocumentsEmailsForm)
