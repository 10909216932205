import React from "react";

import { connect } from "react-redux";
import { formValueSelector, isDirty } from "redux-form";
import PropTypes from "prop-types";

import PriceCalculationSpot from "./PriceCalculationSpot";
import { PriceCalculationSingleStep } from "./PriceCalculationSingleStep";
import { PriceCalculationBillingOptions } from "./PriceCalculationBillingOptions";
import { COST_ITEM_OPTIONS, METER_OPERATOR_COST_ITEM_NAME, SEPARATE_BILL_NO_FEE_OPTION } from "../../../../../constants";
import { updateClickIndexStatus } from "../../../../../actions/costItemActions";
import { meterOperatorExistsOnForm } from "../../../../../utils/validationUtils";

export class PriceCalculationConsumerForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            meterOperatorError: false,
            meterOperatorFeeNotDeletable: false
        }
    }

    componentDidMount() {
        if (
            this.props.meterOperatorInvoicesSupplier === false ||
            this.props.meterOperatorSeparateBillCase === SEPARATE_BILL_NO_FEE_OPTION
        ) {
            this.setState({ meterOperatorFeeNotDeletable: true })
        }
    }

    fieldsRefBaseFee = (fieldsBaseFee) => {
        this.fieldsBaseFee = fieldsBaseFee;
    }

    fieldsMonthlyFees = []
    fieldsRefMonthlyFees = (fieldsMonthlyFees) => {
        this.fieldsMonthlyFees.push(fieldsMonthlyFees);
    }

    removeMeterOperator(fee, fields) {
        const index = fee.findIndex(costItem => {
            return costItem.name === METER_OPERATOR_COST_ITEM_NAME
        })
        fields.remove(index)
    }

    handleMeterOperatorRemoval = () => {
        this.removeMeterOperator(this.props.costItemsBaseFee, this.fieldsBaseFee)
        this.setState({ meterOperatorFeeNotDeletable: false })
    }

    handleMeterOperatorAdding = () => {
        if (this.fieldsMonthlyFees) {
            this.fieldsMonthlyFees.forEach(fieldsRef => {
                this.removeMeterOperator(this.props.costItemsMonthlyFees, fieldsRef)
            })
        }
        this.removeMeterOperator(this.props.costItemsBaseFee, this.fieldsBaseFee)

        this.fieldsBaseFee.push({
            "name": METER_OPERATOR_COST_ITEM_NAME,
            "unit": "ct/y",
            "price_netto": 0,
            "display_name": "Messstellengebühr"
        })
        this.setState({ meterOperatorFeeNotDeletable: true })
    }

    setMeterOperatorError(isError) {
        this.setState({ meterOperatorError: isError })
    }

    render() {        
        const isForBusiness = this.props.isForBusiness
        const priceType = isForBusiness || this.props.isSpot ? 'netto' : 'brutto';
        // TODO why is this value set as a state value and not as a prop
        const meterOperatorExists = meterOperatorExistsOnForm(this.setMeterOperatorError.bind(this))

        return (
            <>
                <PriceCalculationBillingOptions
                    isSpot={this.props.isSpot}
                    isRlm={this.props.isRlm}
                    disabled={this.props.disabled}
                    dispatch={this.props.dispatch}
                    meterOperatorInvoicesSupplier={this.props.meterOperatorInvoicesSupplier}
                    handleMeterOperatorRemoval={this.handleMeterOperatorRemoval.bind(this)}
                    handleMeterOperatorAdding={this.handleMeterOperatorAdding.bind(this)}
                />
                {this.props.isSpot ?
                    <PriceCalculationSpot
                        {...this.props}
                        priceType={priceType}
                        meterOperatorExists={meterOperatorExists}
                        meterOperatorError={this.state.meterOperatorError}
                        fieldsRefBaseFee={this.fieldsRefBaseFee.bind(this)}
                        fieldsRefMonthlyFees={this.fieldsRefMonthlyFees.bind(this)}
                        meterOperatorFeeNotDeletable={this.state.meterOperatorFeeNotDeletable}

                    />

                    :
                    <PriceCalculationSingleStep
                        priceType={priceType}
                        workingPriceMethod={this.props.workingPriceMethod}
                        baseFeeMethod={this.props.baseFeeMethod}
                        disabled={this.props.disabled}
                        dispatch={this.props.dispatch}
                        costItemOptions={this.props.costItemOptions}
                        selectedCostItem={this.props.selectedCostItem}
                        updateClickIndexStatus={this.props.updateClickIndexStatus}
                        saveClicked={this.props.saveClicked}
                        meterOperatorExists={meterOperatorExists}
                        meterOperatorError={this.state.meterOperatorError}
                        fieldsRefBaseFee={this.fieldsRefBaseFee.bind(this)}
                        fieldsRefMonthlyFees={this.fieldsRefMonthlyFees.bind(this)}
                        meterOperatorFeeNotDeletable={this.state.meterOperatorFeeNotDeletable}
                    />
                }
            </>
        );
    }
}

const selector = formValueSelector('productDefinitionForm');

// For a better understanding have a look at this confluence article:
// https://lumenaza.atlassian.net/l/cp/1bebnJ2E
const getCostItemsForAdditionalFees = (monthlyFeesOnForm) => {
    return monthlyFeesOnForm.reduce((acc, fee) => {
        const costItemsExist = typeof fee?.cost_items !== "undefined"

        if (costItemsExist) {
            fee.cost_items.forEach(costItem => {
                acc.push(costItem)
            })
        }
        return acc
    }, [])
}

// For a better understanding have a look at this confluence article:
// https://lumenaza.atlassian.net/l/cp/1bebnJ2E
const getSelectedCostItem = (monthlyFeesOnForm, index, state) => {
    let selectedCostItemName = ''

    // -1 stands for base_fee, all other numbers stand for other monthly fees
    if (index === -1) {
        selectedCostItemName = selector(state, 'bf_selectCostItemField')
    } else {
        selectedCostItemName = monthlyFeesOnForm[index]?.selectCostItemField
    }

    const consumptionPriceModel = selector(state, 'consumption_price_model')
    const productCategory = selector(state, 'product_category')
    const costItems = state.costItems?.editableCostItems[productCategory]?.[consumptionPriceModel]?.monthly_fees || []
    const selectedCostItem = costItems.reduce((item, result) => {
        if (item.name === selectedCostItemName) {
            result = item
        }
        return result
    }, {})

    return selectedCostItem
}

// For a better understanding have a look at this confluence article:
// https://lumenaza.atlassian.net/l/cp/1bebnJ2E
const getCostItemOptions = (costItemsOnForm) => {
    const addedCostItems = costItemsOnForm.reduce((acc, cv) => {
        acc.push(cv.name)
        return acc
    }, [])

    return COST_ITEM_OPTIONS.filter(item => {
        return !addedCostItems.includes(item.value)
    })
}

const mapDispatchToProps = function (dispatch) {
    return {
        updateClickIndexStatus: (status) => {
            return dispatch(updateClickIndexStatus(status))
        }
    };
};

function mapStateToProps(state) {
    const workingPriceMethod = selector(state, 'combi_wp_method');
    const baseFeeMethod = selector(state, 'combi_bf_method');
    const isForBusiness = selector(state, 'is_for_business');
    const consumptionPriceModel = selector(state, 'consumption_price_model');
    const meterOperatorInvoicesSupplier = selector(state, 'meter_operator_invoices_supplier');
    const meterOperatorSeparateBillCase = selector(state, 'meter_operator_separate_bill_case');
    const index = state.costItems.monthlyFeeClickIndex
    const monthlyFeesOnForm = selector(state, 'monthly_fees') || []
    const costItemsBaseFee = selector(state, 'bf_cost_items') || []
    const isRlm = selector(state, 'product_category') === 'rlm';
    const isSpot = consumptionPriceModel === 'spot';

    const costItemsMonthlyFees = getCostItemsForAdditionalFees(monthlyFeesOnForm)
    const costItemsOnForm = [...costItemsBaseFee, ...costItemsMonthlyFees]

    const costItemOptions = getCostItemOptions(costItemsOnForm)
    const selectedCostItem = getSelectedCostItem(monthlyFeesOnForm, index, state)
    return {
        workingPriceMethod,
        isRlm,
        baseFeeMethod,
        isForBusiness,
        consumptionPriceModel,
        meterOperatorInvoicesSupplier,
        meterOperatorSeparateBillCase,
        initialValues: state.productOffers.definition.formData,
        costItemOptions,
        selectedCostItem,
        costItemsBaseFee,
        costItemsMonthlyFees,
        isSpot,
        shouldValidate: () => isDirty("productDefinitionForm")(state)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceCalculationConsumerForm)

PriceCalculationConsumerForm.propTypes = {
    disabled: PropTypes.bool,
    isForBusiness: PropTypes.bool,
    priceCalculation: PropTypes.string,
};
