import _ from 'lodash';
import { SEPARATE_BILL_MAPPING } from '../constants';
import { filterElementsFromDict, setElementsFromPrefix } from '../utils/dictionaryFilteringUtils';

const extraWpInfoSpot = {
    'wp_lower_price_netto': {
        values: {
            name: 'working_price_lower_bound',
            display_name: 'not_used_display_name_min',
            description: 'Minimaler Energiepreis',
            classification: 'spot_working_price_lower_bound',
            unit: 'ct/kWh'
        },
        form_keys: { price_netto: 'wp_lower_price_netto' }
    },
    'wp_upper_price_netto': {
        values: {
            name: 'working_price_upper_bound',
            display_name: 'not_used_display_name_max',
            description: 'Maximaler Energiepreis',
            classification: 'spot_working_price_upper_bound',
            unit: 'ct/kWh'
        },
        form_keys: { price_netto: 'wp_upper_price_netto' },

    }
}

const definitionValuesToForm = {
    working_price_1: { prefix: 'wp', values: ['price_netto', 'price_brutto', 'description', 'display_name'] },
    working_price_upper_bound: { prefix: 'wp_upper', values: ['price_netto'] },
    working_price_lower_bound: { prefix: 'wp_lower', values: ['price_netto'] },
}

const extractWorkingPrice = method => {
    if (!method)
        return null
    // format of priceCalculation 'combi&&wp:xxxx&&bf:xxxxx'
    if (!method.includes('combi'))
        return method;

    const beginningRemoved = method?.split('wp:')[1]
    const result = beginningRemoved?.split('&&bf')[0]
    return result
}

const extractBaseFee = method => {
  if (!method)
      return null
    // format of priceCalculation 'combi&&wp:xxxx&&bf:xxxxx'
    if (!method.includes('combi'))
        return method;
    const result = method?.split('bf:')[1]
    return result
}


export function WorkingPriceItem() { return undefined }

export function BaseFeeItem() { return undefined }

export function PriceCalculationItem() { return undefined }

export default class PriceCalculationValues {
    wpPrefix = 'wp';
    bfPrefix = 'bf';
    pcPrefix = 'pc';
    combiPrefix = 'combi';

    getPriceKey(isForBusiness) {
        return isForBusiness ? 'price_netto' : 'price_brutto';
    }

    getWorkingPriceSpotValues(formValues) {
        let values = []

        if (formValues['wp_display_name']) {
            const isRlm = formValues['product_category'] === 'rlm'
            const classification = isRlm ? 'spot_working_price_rlm' : 'spot_working_price'
            values = [{
                name: 'working_price_1',
                display_name: formValues['wp_display_name'],
                description: formValues['wp_description'],
                classification: classification,
                price_netto: 0,
                parameters: [
                    {
                        name: 'spot_market_display_name',
                        value: formValues['wp_spot_market_display_name']
                    },
                    {
                        name: 'spot_market_price_explanation',
                        value: formValues['wp_spot_market_price_explanation']
                    }
                ]
            }];
        }
        Object.keys(extraWpInfoSpot).forEach((key) => {
            if (formValues[key]) {
                let newItem = { ...extraWpInfoSpot[key]['values'] };
                const formKeys = extraWpInfoSpot[key]['form_keys'];
                for (const [formKey, formValue] of Object.entries(formKeys)) {
                    newItem[formKey] = formValues[formValue]
                }
                values.push(newItem);
            }
        });
        const extraWorkingPrices = _.get(formValues, 'working_prices_extra', []);
        const res = [...values, ...extraWorkingPrices];
        return res;
    }

    getWorkingPriceSingleStepValues(formValues) {
        let values = []

        if (formValues['wp_display_name']) {
            values.push({})
            values[0]['name'] = 'working_price_1'
            values[0]['display_name'] = formValues['wp_display_name']
            values[0]['description'] = formValues['wp_description']
            values[0]['classification'] = 'unique_working_price'

            const isForBusiness = formValues['is_for_business']
            const priceKey = this.getPriceKey(isForBusiness)
            values[0][priceKey] = formValues[`wp_${priceKey}`]
        }

        return values
    }

    getMonthlyFeeItemValues(formValues) {
        const isForBusiness = formValues['is_for_business']
        const isSpot = formValues['consumption_price_model'] === 'spot';
        const isRlm = formValues['product_category'] === 'rlm';        
        let baseFeeValues = [];
        let additionalFeeValues = []
        if(isRlm){
            let bfRlm = new BaseFeeItem();
            bfRlm['classification'] = 'base_fee_rlm';
            bfRlm['price_netto'] = 0;
            bfRlm['name'] = 'base_fee';
            bfRlm['display_name'] = 'Kosten Netzbetreiber';
            bfRlm['description'] = 'Die Kosten, die durch den Netzbetreiber in Rechnung gestellt werden, werden 1:1 an den Endkunden weitergereicht.';
            baseFeeValues.push(bfRlm);

        }
        let bf = new BaseFeeItem();
        bf = filterElementsFromDict(bf, formValues, this.bfPrefix);

        if (!_.isEmpty(bf) && !isRlm) {
            if (bf['cost_items'] && bf['cost_items'].length === 0) {
                delete bf['cost_items']
            }
            bf['name'] = 'base_fee';
            bf['classification'] = 'base_fee';

            if (isForBusiness || isSpot) {
                delete bf['price_brutto']
            }

            if (!isForBusiness && !isSpot) {
                delete bf['price_netto']
            }

            delete bf['selectCostItemField']

            baseFeeValues.push(bf);
        }
        

        if (!_.isEmpty(formValues['monthly_fees']) && isSpot) {
            let serviceCostsCount = 1
            additionalFeeValues = formValues['monthly_fees'].map((fee) => {
                if (fee['cost_items'] && fee['cost_items'].length === 0) {
                    delete fee['cost_items']
                }
                if (fee['classification'] === 'meter_fee') {
                    fee['name'] = 'smart_meter_costs'
                }
                if (fee['classification'] === 'service_fee') {
                    fee['name'] = `service_costs_${serviceCostsCount}`
                    serviceCostsCount++
                }
                delete fee['selectCostItemField']

                return fee
            })
        }

        if (baseFeeValues.length === 0) {
            return null
        }
        return { 'monthly_fees': [...baseFeeValues, ...additionalFeeValues] }
    }

    getPriceCalculationItemValues(formValues) {
        let pc = new PriceCalculationItem();
        const isRlm = formValues['product_category'] === 'rlm' 
        pc = filterElementsFromDict(pc, formValues, this.pcPrefix);
        const bf = extractBaseFee(pc['method'])
        

        if (!isRlm && (_.isEmpty(pc) || !bf)) {
            return null;
        }

        /* 
            When you change from single_step to spot and don't change 
            the price calc method, then the combi price calc value isn't 
            updated with spot_price. That's why we have this code below.
        */
        const isSpot = formValues['consumption_price_model'] === 'spot'
        
        const baseFee = extractBaseFee(formValues['pc_method'])
        if (isSpot && !isRlm) {
            pc['method'] = `combi&&wp:spot_price&&bf:${baseFee}`
        }else if (isRlm) {
            pc['method'] = `combi&&wp:spot_price&&bf:fix`
        
        } else {
            const workingPrice = extractWorkingPrice(formValues['pc_method'])
            pc['method'] = `combi&&wp:${workingPrice}&&bf:${baseFee}`
        }

        return { 'price_calculation': pc }
    }

    getWorkingPriceItemValues(formValues) {
        let workingPriceValues = []
        const consumptionModel = _.get(formValues, 'consumption_price_model', 'single_step')

        if (consumptionModel === 'spot') {
            workingPriceValues =
                this.getWorkingPriceSpotValues(formValues)
        } else if (consumptionModel === 'single_step') {
            workingPriceValues =
                this.getWorkingPriceSingleStepValues(formValues)
        }

        if (workingPriceValues.length === 0) {
            return null
        }

        return { 'working_prices': workingPriceValues }
    }

    getMeterOperatorItemValues(formValues) {
        // We need three cases in the POD frontend (meter_operator_separate_bill_case). 
        // We need only two cases in the Lumenaza Platform (meter_operator_separate_bill).
        // Thats why we need to map values between these two parameters.
        // We save both on the POD, because for existing PODs we couldn't translate the value for
        // meter_operator_separate_bill into the correct value for meter_operator_separate_bill_case.

        const separateBillCase = formValues['meter_operator_separate_bill_case']
        const separateBillValue = SEPARATE_BILL_MAPPING[separateBillCase]
        const invoicesSupplierValue = formValues['meter_operator_invoices_supplier']

        const meterOperatorValues = {
            'meter_operator_invoices_supplier': invoicesSupplierValue,
            'meter_operator_separate_bill': separateBillValue,
            'meter_operator_separate_bill_case': separateBillCase
        }

        return {
            'meter_operator_info': meterOperatorValues,
        }
    }

    setValuesFromForm(formValues) {
        const monthlyFeeValues = this.getMonthlyFeeItemValues(formValues);
        const workingPriceValues = this.getWorkingPriceItemValues(formValues)
        const pcValues = this.getPriceCalculationItemValues(formValues);
        const meterOperatorValues = this.getMeterOperatorItemValues(formValues)

        return {
            ...workingPriceValues,
            ...pcValues,
            ...monthlyFeeValues,
            ...meterOperatorValues,
        };
    }

    cleanupFormValues(pcValues, wpValues, bfValues, isForBusiness) {
        const priceKey = this.getPriceKey(isForBusiness)

        if (pcValues.method === 'cost_plus') {
            delete pcValues['amount_below_competition_bf']
            delete pcValues['amount_below_competition_wp']
            delete wpValues[priceKey]
            delete bfValues[priceKey]
        }
        if (pcValues.method === 'fix') {
            delete pcValues['amount_below_competition_bf']
            delete pcValues['amount_below_competition_wp']
            delete pcValues['base_fee_margin_min']
            delete pcValues['base_fee_margin_min_unit']
            delete pcValues['working_price_margin_min']
            delete pcValues['working_price_margin_min_unit']
        }
        if (pcValues.method === 'algorithmic') {
            delete wpValues[priceKey]
            delete bfValues[priceKey]
        }
    }

    setWorkingPriceValues(workingPrices) {
        const wpValues = {};

        workingPrices.forEach(wp => {
            const wpDef = _.get(definitionValuesToForm, wp.name, {});
            _.get(wpDef, 'values', []).forEach(item => {
                wpValues[`${wpDef.prefix}_${item}`] = _.get(wp, item)
            });

        });
        // TODO REFACTOR THIS TO BE INSIDE THE FOREACH
        if (workingPrices[0]?.parameters) {
            wpValues['wp_spot_market_display_name'] = workingPrices[0]?.parameters[0]?.value
            wpValues['wp_spot_market_price_explanation'] = workingPrices[0]?.parameters[1]?.value
        }
        return wpValues
    }

    setMeterOperatorValues(podObject) {
        const meterOperatorInfo = podObject['meter_operator_info']
        if (typeof meterOperatorInfo === "undefined") {
            return null
        }

        const invoicesSupplierValue = meterOperatorInfo['meter_operator_invoices_supplier']
        const separateBillCaseValue = meterOperatorInfo['meter_operator_separate_bill_case']

        return {
            'meter_operator_separate_bill_case': separateBillCaseValue,
            'meter_operator_invoices_supplier': invoicesSupplierValue
        }
    }

    setFormValues(podObject) {
        let formValues = {};
        const isForBusiness = _.get(podObject, 'is_for_business', false);
        let pcValues = _.get(podObject, 'price_calculation', {});

        const workingPrices = _.get(podObject, 'working_prices', []);
        const monthlyFees = _.get(podObject, 'monthly_fees', []);
        const [bfValues, ...additionalValues] = monthlyFees;

        this.cleanupFormValues(pcValues, workingPrices, bfValues, isForBusiness)

        Object.assign(formValues, setElementsFromPrefix(pcValues, this.pcPrefix));
        Object.assign(formValues, setElementsFromPrefix(bfValues, this.bfPrefix));
        Object.assign(formValues, { 'monthly_fees': additionalValues });
        Object.assign(formValues, this.setWorkingPriceValues(workingPrices));
        Object.assign(formValues, this.setMeterOperatorValues(podObject));

        const category = _.get(podObject, "product_category");
        const consumptionModel = _.get(podObject, 'consumption_price_model')
        if (category !== 'producer' && consumptionModel === 'spot') {

            const extraWorkingPrices = workingPrices.filter(item => ['percentage_service_fee_on_spot_working_price', 'additional_ct_kwh_price'].
                includes(item.classification));
            Object.assign(formValues, { 'working_prices_extra': extraWorkingPrices });
        }

        // Set values for fields out of which combi value is calculated
        formValues['combi_wp_method'] = extractWorkingPrice(pcValues['method']) || ''
        formValues['combi_bf_method'] = extractBaseFee(pcValues['method']) || ''

        return formValues;
    }
}
