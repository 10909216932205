import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import {SECONDARY_WHITE_COLOR, SECONDARY_COLOR} from "../../../constants/css";
import GradientBG from "../../../images/background/lumenaza_gradient_background_v2_light.png";


export const StartPageBackgroundGradient = styled(Row)`
  background-image: url(${GradientBG});
  background-color: ${SECONDARY_COLOR};
  background-repeat: no-repeat;
  background-size: 60%;
`

export const ImageCol = styled(Col)`
  height: 100vh;
`

export const StartPageHeading = styled.h1`
  color: ${SECONDARY_WHITE_COLOR};
  font-size: 64px;
  margin-bottom: 60px;
`

export const StartPageSubHeading = styled.h3`
  color: ${SECONDARY_WHITE_COLOR};
  font-size: 40px;
  margin-bottom: 70px;
`

export const StartPageContentCol = styled(Col)`
    display: flex;
    align-items: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
`

export const LumenazaLogo = styled.img`
  width: 22rem;
  margin-left: 5rem;
  margin-top: 6rem;
`