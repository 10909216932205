export const IS_TEST = false;

export const PRODUCT_OFFER_DEFINITION_STATUS = {
  activated: { label: "Aktiviert" },
  draft: { label: "Entwurf" }
};

export const PRODUCT_CATEGORY_OPTIONS = {
  slp: "Standard Stromverbraucher",
  heatpump: "Heizpumpe",
  heatstorage: "Heizstrom",
  e_mobility: "E-Mobility",
  rlm: "Registrierende Lastgangmessung (RLM)"
};

export const PRODUCT_OFFER_ENDPOINT = "/api/product_definitions/";
export const COST_ITEMS_ENDPOINT = "/api/cost_items/";

export const GRAY = "#4a5c66";

export const LUMENAZA_BLUE = "#337ab7";

export const LUMENAZA_GREEN = "#80b100";

export const LIGHT_GREEN = "#b3e37f";

export const LIGHT_GRAY = "#808d95";

export const DARK_GRAY = "#4B5C66";

export const MENU_OPTIONS = [
  {
    label: "wechseln",
    href: "/salespartner",
    isLogoutAction: false,
  },
  { label: "Profil", href: "/user", isLogoutAction: false },
  { label: "Logout", href: "#", isLogoutAction: true },
];

export const MENU_OPTIONS_SP = [
  { label: "Profil", href: "/user", isLogoutAction: false },
  { label: "Logout", href: "#", isLogoutAction: true },
];

export const PRODUCT_IS_BUSINESS_FIELD_OPTIONS = [
  { label: "Haushaltkunden", value: false },
  { label: "Gewerbekunden", value: true },
];

export const PRODUCT_CATEGORY_FIELD_OPTIONS = [
  { label: "Einfacher Haushaltsanschluss", value: "slp" },
  { label: "Wärmepumpe mit separatem Zählpunkt", value: "heatpump" },
  { label: "Heizstrom mit separatem Zählpunkt", value: "heatstorage" },
  { label: "Ladepunkt Elektromobilität mit separatem Zählpunkt", value: "e_mobility" },
  { label: "Registrierende Lastgangmessung (RLM)", value: 'rlm' },
];

// after spot implementation is done this has to be reverted
export const PRODUCT_TARIF_TYPE_FIELD_OPTIONS = [
  { label: "Fixtarif (einheitlicher Preis für den Gesamtverbrauch)", value: "single_step" },
  { label: "Dynamischer Tarif (stündliche Spot Markt Preise)", value: "spot" }
];

export const PRODUCT_PRICE_CALCULATION_FIELD_OPTIONS = [
  { label: "Wettbewerb/Margen Pricing", value: "algorithmic" },
  { label: "Einheitspreis", value: "fix" },
  { label: "Kostenaufschlagsmethode", value: "cost_plus" },
];

export const PRODUCT_PRICE_CLASSIFICATION_OPTIONS = [
  { label: "Grundgebühr inkl. Netzentgelte", value: "base_fee" },
];

export const ADDITIONAL_PRICE_CLASSIFICATION_OPTIONS = [
  { label: "Service Gebühr", value: "service_fee" },
  { label: "Smart Meter Gebühr", value: "meter_fee" },
];

export const PRICE_UNITS_OPTIONS_WP = [
  { label: "%", value: "1/100" },
  { label: "ct/kWh", value: "ct/kWh" },
];

export const PRICE_UNITS_OPTIONS_BF = [
  { label: "%", value: "1/100" },
  { label: "ct/Monat", value: "ct/m" },
];
export const IS_FOR_BUSINESS_LABEL = {
  true: "Gewerbe",
  false: "Haushaltkunden",
};

export const BASIC_USER = "";
export const BASIC_PWD = "";
export const POD_NAME = "Product Catalog";

export const PRODUCT_PARAMETER_PERIOD_OPTIONS = [
  { label: "Wochen", value: "w" },
  { label: "Monate", value: "m" },
];

export const PRODUCT_PARAMETER_CUSTOMER_CYCLE_OPTIONS = [
  { label: "Jährlich zum VNB Turnus", value: "yearly_coupled" },
  { label: "Monatliche Rechnung", value: "monthly_decoupled" },
];

export const CUSTOMER_ADDRESSING_METHOD_OPTIONS = [
  { label: "förmlich", value: "formal" },
  { label: "persönlich", value: "personal" },
  { label: "individuelles Set", value: "custom" },
];

export const CUSTOMER_ADDRESSING_COMBINATION_OPTIONS = [
  { label: "Sie & Nachname", value: true },
  { label: "Du & Vorname", value: false },
];

export const COMPANY_TYPE_OPTIONS = [

    { label: "GmbH", value: "gmbh" },
    { label: "AG", value: "ag" }
];

export const EMAIL_TYPE_PREFIX = "et";
export const DOC_TYPE_PREFIX = "dt";

export const TASK_POOLING_ENDPOINT = "/taskpooling";

export const PREVIEWER_ENDPOINT = "/api/previewer/";

export const INFO_PRODUCT_NAME =
  "Der Display Produktname wird für die Kundenkommunikation verwendet. Dieser ist das Aushängeschild zum Kunden.";

export const EDITOR_TYPES_MAP = {
  'installment_bill_email': "Abschlagsrechnung",
  'monthly_bill_email': "Monatsrechnung",
  'year_bill_email': "Jahresrechnung",
  'final_bill_email': "Endabrechnung",
  'monthly_bill_email_storno': "Storno Monatsrechnung",
  'year_bill_email_storno': "Storno Jahresrechnung",
  'final_bill_email_storno': "Storno Endabrechnung",
  'order_confirmation_email': "Auftragsbestätigung",
  'order_confirmation_email_producer': "Auftragsbestätigung",
  'password_reset': "Passwort zurücksetzen",
  'contract_revocation_confirmation_email': "Widerrufsbestätigung",
  'welcome_email_consumer': "Willkommen",
  'cancellation_confirmation_email': "Kündigungsbestätigung",
  'installment_bill': "Abschlagsrechnung",
  'update_installment_plan': "Abschlagsneuberechnung",
  'monthly_bill': "Monatsrechnung",
  'year_bill': "Jahresrechnung",
  'final_bill': "Endabrechnung",
  'monthly_bill_storno': "Storno Monatsrechnung",
  'year_bill_storno': "Storno Jahresrechnung",
  'final_bill_storno': "Storno Endabrechnung",
  'delivery_confirmation_consumer': "Lieferbestätigung",
  'delivery_confirmation_producer': "Lieferbestätigung",
  'price_change_confirmation_consumer': "Ankündigung Preisänderung",
  'price_change_confirmation_producer': "Ankündigung Preisänderung",
  'feed_in_bill_email': 'Einspeiserechnung',
  'feed_in_bill': 'Einspeiserechnung',
  'feed_in_bill_email_storno': 'Storno Einspeiserechnung',
  'feed_in_bill_storno': 'Storno Einspeiserechnung',
  'dist_cost_clearing_bill': 'Schlussrechnung Vermarktungskosten',
  'dist_cost_clearing_bill_storno': 'Storno Schlussrechnung Vermarktungskosten',
  'dist_cost_clearing_bill_email': 'Schlussrechnung Vermarktungskosten',
  'dist_cost_clearing_bill_email_storno': 'Storno Schlussrechnung Vermarktungskosten',
  'payment_method_change_consumer': 'Wechsel der Zahlmethode zu SEPA',
  'contract_start_email': 'Lieferbeginn',
  'product_switch': 'Produktwechsel',
};

export const MONTHLY_CUCOS = [
  'monthly_bill_email',
  'monthly_bill_email_storno',
  'monthly_bill',
  'monthly_bill_storno'
]

function boldString(str, substr) {
  const strRegExp = new RegExp(substr, "g");
  return str.replace(strRegExp, "<b>" + substr + "</b>");
}

const target =
  "Haushaltskunden:\n" +
  "Privatkunden \n" +
  "-\n" +
  "für diese werden die Stromkosten in Brutto ausgewiesen. Dies gilt vor allen Dingen auch für die Angaben auf den \n" +
  "Rechnungsdokumenten\n" +
  "Gewerbekunden \n" +
  "–\n" +
  "für diese ist die Ausweisung der Mehrwertsteuer auf allen Rechnungsdokumenten relevant. Die Preise werden in N\n" +
  "etto ausgewiesen";

export const INFO_TARGET_GROUP = boldString(target, "Haushaltskunden");

export const MEDIA_ENDPOINT = "/media";

export const DEFAULT_TEXT_VARIABLES = [
    {label: 'Kundenservice E-Mail', variable_name: 'definition_credits_provider_mail'},
    {label: 'Kundenservice Telefonnr.', variable_name: 'definition_credits_provider_phone'},
    {label: 'Website URL', variable_name: 'definition_credits_provider_url'},
    {label: 'Produktname', variable_name: 'tariff_name'},
    {label: 'Kundennummer', variable_name: 'saas_customer_id'},
    {label: 'Vertragsnummer', variable_name: 'saas_contract_id' },
    {label: 'Nutzername', variable_name: 'username'},
    {label: 'Bestelldatum', variable_name: 'order_date'},
    {label: 'Lieferstart', variable_name: 'deliv_start'},
    {label: 'Lieferende', variable_name: 'deliv_end'},
    {label: 'Rechnungsnummer', variable_name: 'bill_number'},
    {label: 'Rechnungsnummer der stornierten Rechnung', variable_name: 'parent_bill_number'},
    {label: 'Rechnungsdatum der stornierten Rechnung', variable_name: 'parent_bill_date'},
    {label: 'Lieferadresse: Ort', variable_name: 'deliv_city'},
    {label: 'Lieferadresse: Straße', variable_name: 'deliv_street'},
    {label: 'Lieferadresse: Hausnummer', variable_name: 'deliv_house_number'},
    {label: 'Lieferadresse: PLZ', variable_name: 'deliv_zipcode'},
    {label: 'Rechnung Fälligkeitsdatum', variable_name: 'due_date'},
];
export const CONSUMER_TEXT_VARIABLES = [
    {label: 'Abrechnungsjahr/e', variable_name: 'year'},
    {label: 'Neuer Abschlag (brutto)', variable_name: 'brutto_installment_amount'},
    {label: 'USt. Höhe in Abschlag', variable_name: 'installment_tax'},
    {label: 'Abschlag-Monat', variable_name: 'month'},
    {label: 'Abschlag-Jahr', variable_name: 'year'},
    {label: 'Einzugsdatum-Abschlag', variable_name: 'installment_date'},
    {label: 'Preiserhöhung Eingang der Sonderkündigung', variable_name: 'preferred_last_day_of_termination_notice'},
    {label: 'Preiserhöhung Vortag', variable_name: 'day_before_price_change'},
    {label: 'Preiserhöhung in Krafttreten', variable_name: 'date_of_price_change'},
    {label: 'Abschlag vor Preiserhöhung', variable_name: 'current_installment_payment'},
    {label: 'Abschlag nach Preiserhöhung', variable_name: 'new_installment_payment'},
    {label: 'USt. Höhe in Abschlag nach Preiserhöhung', variable_name: 'new_installment_payment_tax'},
    {label: 'Produktwechsel Datum', variable_name: 'validity_start'},
];
export const PRODUCER_TEXT_VARIABLES = [
    {label: 'Einspeise-Monat&Jahr', variable_name: 'deliv_month'},
    {label: 'Erzeugungsart', variable_name: 'energy_source'},
    {label: 'Installierte Leistung in kWp', variable_name: 'plant_capacity'},
    {label: 'Vermarktungskosten, Monat', variable_name: 'previous_month_str'},
];
export const MONTH_TEXT_VARIABLES = [
  {label: 'Abgerechneter Monat', variable_name: 'bill_month'},
  {label: 'Preiserhöhung Eingang der Sonderkündigung', variable_name: 'preferred_last_day_of_termination_notice'},
  {label: 'Preiserhöhung Vortag', variable_name: 'day_before_price_change'},
  {label: 'Preiserhöhung in Krafttreten', variable_name: 'date_of_price_change'},
  {label: 'Produktwechsel Datum', variable_name: 'validity_start'},
];
export const DIRECT_MARKETING_OPTIONS = [
  { label: "Direktvermarktung im 2 Strommodel", value: false },
  { label: "sonstige Direktvermarktung", value: true },
];

export const FIXED_REMUNERATION = 'fixed_remuneration'

export const PAYMENT_MODEL_OPTIONS = [
  { label: "Durchschnittlicher Marktwert", value: "ct/kWh" },
  { label: "Spot Markt Preise", value: "ct/h/kWh" },
  { label: "Festpreis", value: FIXED_REMUNERATION },
];

export const SUPPORTED_PLANT_TYPES_OPTIONS = [
  { label: "Solar", value: "solar" },
  { label: "Wind", value: "wind" },
  // TODO: Uncomment as soon as hydro is enabled in Lumenaza Platform
  // { label: "Hydro", value: "water" },
  { label: "Gas", value: "gas" },
];

export const SUPPORTED_SERVICE_FEE_TYPES_OPTIONS = [
  { label:'Einheitsentgelt', value: "single_value" },
  { label:'Abhängig zur installierten Leistung', value: "matrix" },
  { label:'Einmalgebühr', value: "unique_fee" },
];

export const DISTRIBUTION_SERVICE_FEE_TYPE = [
  { label:'Vermarktungskosten', value: "distribution_costs" }
];

export const INITIAL_DISTRIBUTION_FEE_VALUES = {
  unit: '',
  description: "Vermarktungskosten",
  price_netto: 0.0,
  classification: "distribution_cost_fee_producer"
}
export const SUPPORTED_SERVICE_FEE_UNIT_TYPES_OPTIONS = [
  { label:'ct/kWh', value: 'ct/kWh' },
  { label:'ct/Monat', value: 'ct/m' },
  { label:'%', value: '1/100' },
];

export const UNIQUE_SERVICE_FEE_UNIT_TYPE_OPTION = [
  { label:'ct', value: 'ct' }
]

export const SERVICE_FEE_UNIQUE_FEE_UNIT_PARAMS = {
  'ct': {
    classification: 'sign_up_fee',
    description: 'einmalige Gebühr, die auf der ersten Einspeiserechnung abgerechnet wird'
  }
}
export const SERVICE_FEE_SINGLE_VALUE_PARAMS = {
  '1/100': {
    classification: 'percentage_service_fee_on_feedin_payout',
    description: 'Variables Entgelt'
  },
  'ct/m': {
    classification: 'service_fee_producer',
    description: 'Fixes Entgelt'
  },
  'ct/kWh': {
    classification: 'service_fee_producer',
    description: 'Fixes Entgelt'
  },
  'ct': {
    classification: 'sign_up_fee',
    description: 'einmalige Gebühr, die auf der ersten Einspeiserechnung abgerechnet wird'
  }
};

export const SERVICE_FEE_MATRIX_PARAMS = {
  '1/100': {
    classification: 'percentage_service_fee_on_feedin_payout_producer_plant_capacity',
    description: 'Variables Entgelt'
  },
  'ct/m': {
    classification: 'service_fee_producer_plant_capacity',
    description: 'Fixes Entgelt'
  },
  'ct/kWh': {
    classification: 'service_fee_producer_plant_capacity',
    description: 'Fixes Entgelt'
  }
};

export const PRODUCT_OFFER_STEPS = {
  1: 'productDef',
  2: 'priceCalc',
  3: 'contactParm',
  4: 'baseDesign',
  5: 'invoiceDocs',
  6: 'summary'
}

export const MODAL_TITLE_STATES = {
  'activation': 'aktivieren',
  'synchronization': 'synchronisieren',
  'duplication': 'duplizieren'
}

export const MODAL_PROCESSES_STATES = {
  'activation': 'aktiviert',
  'synchronization': 'synchronisiert'
}

export const BOUND_TOOLTIPS = {
  upper: 'Stündliche Spot Markt Preise oberhalb der Obergrenze werden nicht 1:1 an den Endkunden weitergegeben, sondern auf die Obergrenze herabgesetzt. Dies schützt den Kunden vor starken Preisschwankungen.',
  lower: 'Stündliche Spot Markt Preise unterhalb der Untergrenze werden nicht 1:1 an den Endkunden weitergegeben, sondern auf die Untergrenze angehoben.'
}

export const SOCIAL_MEDIA_OPTIONS = [
  { label: "Facebook", value: "facebook" },
  { label: "Instagram", value: "instagram" },
  { label: "Twitter", value: "twitter" },
  { label: "Youtube", value: "youtube" },
  { label: "LinkedIn", value: "linked_in" },
  { label: "Xing", value: "xing" },
];

export const PRODUCT_DEFINTION_FIELD_NAMES = [
  "owner",
  "display_name",
  "product_description",
  "is_for_business",
  "product_category",
  "consumption_price_model",
  "period_billing_interval",
  "is_sonstige_direktvermarktung",
  "payment_model",
  "pc_method"
]

export const INVOICES_SUPPLIER_TRUE_LABEL = "Lieferant rechnet Messstellengebühren gegenüber Endkunde ab."

// TODO: Uncomment as soon as INVOICES_SUPPLIER_FALSE_LABEL enabled in Lumenaza Platform
// export const INVOICES_SUPPLIER_FALSE_LABEL = "Messstellenbetreiber rechnet Messstellengebühren direkt mit dem Endkunden ab. Der Stromlieferant ist unbeteiligt."

export const INVOICES_SUPPLIER_OPTIONS = [
  { 
    value: true,
    label: INVOICES_SUPPLIER_TRUE_LABEL
  },
  // TODO: Uncomment as soon as INVOICES_SUPPLIER_FALSE_LABEL enabled in Lumenaza Platform
  // {
  //   value: false,
  //   label: INVOICES_SUPPLIER_FALSE_LABEL
  // }
];

export const SEPARATE_BILL_WITH_FEE_OPTION = "separate_bill_with_fee"
export const NO_SEPARATE_BILL_OPTION = "no_separate_bill"
export const SEPARATE_BILL_NO_FEE_OPTION = "separate_bill_no_fee"

export const SEPARATE_BILL_WITH_FEE_OPTION_LABEL = "Der Stromtarif enthält einen pauschalen Betrag für Messstellengebühren. Dieser muss in einer monatlichen Gebühr reflektiert werden. Einmal jährlich wird die Differenz zu vom MSB tatsächlich erhobenen Messstellengebühren dem Kunden separat in Rechnung gestellt."
export const NO_SEPARATE_BILL_OPTION_LABEL = "Der Stromtarif enthält einen pauschalen Betrag für Messstellengebühren. Dieser muss in einer monatlichen Gebühr reflektiert werden. Eine Differenz zu vom MSB tatsächlich erhobenen Messstellengebühren wird nicht ermittelt."
export const SEPARATE_BILL_NO_FEE_OPTION_LABEL = "Der Stromtarif enthält keine Messstellengebühren. Die Kosten werden einmal jährlich über eine separate Rechnung vom Stromlieferanten 1:1 an den Kunden weiter gegeben."

export const SEPARATE_BILL_CASES_OPTIONS = [
  {
    value: SEPARATE_BILL_WITH_FEE_OPTION,
    label: SEPARATE_BILL_WITH_FEE_OPTION_LABEL
  },
  {
    value: NO_SEPARATE_BILL_OPTION,
    label: NO_SEPARATE_BILL_OPTION_LABEL
  },
  {
    value: SEPARATE_BILL_NO_FEE_OPTION,
    label: SEPARATE_BILL_NO_FEE_OPTION_LABEL
  }
];

export const SEPARATE_BILL_MAPPING = {
  [SEPARATE_BILL_WITH_FEE_OPTION]: true,
  [SEPARATE_BILL_NO_FEE_OPTION]: true,
  [NO_SEPARATE_BILL_OPTION]: false
}

export const METER_OPERATOR_TOOLTIP = "Der Messstellenbetreiber (MSB) erhebt nach MsBG Gebühren für den Messstellenbetrieb. Die Abrechnung der Messstellengebühren kann gegenüber den Stromlieferanten mittels Marktkommunikations-Prozessen erfolgen. Die Abrechnung kann aber auch am Stromlieferanten vorbei direkt zwischen Messstellenbetreiber und Endkunden statt finden. Für intelligente Messsystem folgen die Messstellengebühren einer komplexen Logik und können nicht bei Vertragsabschluss kundenindividuell in die Preisberechnung einfließen. Daher muss hier ein spezieller Umgang mit den Messstellengebühren definiert werden. Die AGB des Stromtarifs müssen dementsprechend formuliert werden."

export const METER_OPERATOR_COST_ITEM_NAME = "msb_sp_defined"
export const COST_ITEM_OPTIONS = [
  {
    label: "Messstellengebühren",
    value: METER_OPERATOR_COST_ITEM_NAME,
    helperText: "Der Messstellenbetreiber erhebt nach MsBGGebühren pro betriebenem Zähler. Für intelligente Messsystem folgen diese einer komplexen Logik und können nicht bei Vertragsabschluss vertragsindividuell in die Preisberechnung einfließen. Eine pauschale Höhe von inkludierten Messstellengebühren (z.B. 20€/Jahr) unabhängig von der beim Kunden installierten Hardware können einer monatlichen Gebühr zugeordnet werden und somit im Stromtarif inkludiert werden. Die inkludierte Höhe der Messstellengebühren werden außerdem wie gesetzlich vorgesehen auf der Stromrechnung ausgewiesen."
  }
]

export const COST_ITEM_TOOLTIP = "Den Preiselementen eines Stromtarifs werden gesetzlich vordefinierte Kosten wie Umlagen & Abgaben zugeordnet. Über diesen Standard hinaus können hier Kosten einem Preiselement des Stromtarifs zugeordnet werden. Über die Zuordnung von Kosten zu einem Preis bzw. einer Gebühr werden diese in der vertragsindividuellen Preisberechnung bei Vertragsabschluss berücksichtigt."

export const FIELD_REQUIRED_ERROR = "Pflichteingabe"
export const PASSWORDS_DIFFERENT_ERROR = "Passwörter müssen gleich sein"
export const PASSWORD_INVALID_ERROR = "Passwort-Anforderungen nicht erfüllt"

export const PASSWORD_HINT_MIN_LENGTH = "Mindestens 8 Zeichen"
export const PASSWORD_HINT_NUMBER_REQUIRED = "Mindestens eine Nummer"
export const PASSWORD_HINT_SPECIAL_CHAR_REQUIRED = "Mindestens ein Sonderzeichen"
