import React, { useEffect } from 'react'

import { Row } from 'react-bootstrap'
import { change, Field } from 'redux-form'
import { required } from 'redux-form-validators'
import styled from 'styled-components'

import {
  INVOICES_SUPPLIER_OPTIONS,
  METER_OPERATOR_TOOLTIP,
  PRODUCT_PARAMETER_CUSTOMER_CYCLE_OPTIONS,
  SEPARATE_BILL_CASES_OPTIONS,
  SEPARATE_BILL_NO_FEE_OPTION,
} from '../../../../../constants'
import SelectWrapper from '../../../../common/forms/SelectWrapper'
import { Tooltip } from '../../../../common/lib/Tooltip'
import {
  OuterFormColLeft,
  OuterFormColRight,
} from '../../../../common/styles/FormStyles'

const StyledRow = styled(Row)`
  margin: 55px 0 35px 0;
  font-weight: 700;
`

const Container = styled(Row)`
  margin-bottom: 140px;
`

const PriceCalculationBillingOptions = (props) => {
  const { isSpot, dispatch, disabled, meterOperatorInvoicesSupplier } = props

  useEffect(() => {
    if (isSpot) {
      dispatch(
        change(
          'productDefinitionForm',
          'period_billing_interval',
          'monthly_decoupled'
        )
      )
    } else {
      dispatch(
        change(
          'productDefinitionForm',
          'period_billing_interval',
          'yearly_coupled'
        )
      )
    }
    // eslint-disable-next-line
  }, [])

  const onInvoicesSupplierChange = (value) => {
    if (value === true) {
      props.handleMeterOperatorRemoval()
      dispatch(
        change('productDefinitionForm', 'meter_operator_separate_bill_case', '')
      )
    } else {
      props.handleMeterOperatorAdding()
      dispatch(
        change(
          'productDefinitionForm',
          'meter_operator_separate_bill_case',
          'no_separate_bill'
        )
      )
    }
  }

  const onSeparateBillCaseChange = (value) => {
    if (value === SEPARATE_BILL_NO_FEE_OPTION) {
      props.handleMeterOperatorAdding()
    } else {
      props.handleMeterOperatorRemoval()
    }
  }

  return (
    <Container>
      <OuterFormColLeft md={6} lg={6}>
        <StyledRow>
          ABRECHNUNG STROMVERBRAUCH
          <Tooltip>
            Der Abrechnungszyklus bestimmt, in welchen Zyklen die Kunden eine
            Stromverbrauchs-abrechnung erhalten.
          </Tooltip>
        </StyledRow>
        <Field
          name="period_billing_interval"
          labelText="Abrechnungszyklus der Kunden"
          component={SelectWrapper}
          optionsList={PRODUCT_PARAMETER_CUSTOMER_CYCLE_OPTIONS}
          validate={required()}
          disabled={true}
          className="mt-5"
        />
      </OuterFormColLeft>
      {!props.isRlm && 
        <OuterFormColRight md={6} lg={6}>
          <StyledRow>
            ABRECHNUNG MESSSTELLENGEBÜHREN
            <Tooltip>
              {METER_OPERATOR_TOOLTIP}
            </Tooltip>
          </StyledRow>
          <Field
            name="meter_operator_invoices_supplier"
            labelText="Abrechnung der Messstellengebühren"
            component={SelectWrapper}
            optionsList={INVOICES_SUPPLIER_OPTIONS}
            validate={required()}
            disabled={disabled}
            className="mt-5"
            onCustomChange={onInvoicesSupplierChange}
          />
          {meterOperatorInvoicesSupplier ? (
            <Field
              name="meter_operator_separate_bill_case"
              labelText="Art der Abrechnung gegenüber dem Endkunden"
              component={SelectWrapper}
              optionsList={SEPARATE_BILL_CASES_OPTIONS}
              validate={required()}
              disabled={disabled}
              className="mt-5"
              onCustomChange={onSeparateBillCaseChange}
            />
          ) : null}
        </OuterFormColRight>}
    </Container>
  )
}

export { PriceCalculationBillingOptions }
