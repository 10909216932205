import React from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';

import styled from 'styled-components';
import { Container, Row, Col } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Button from "../common/styles/Button";
import LogOutButton from '../common/LogoutButton';
import { 
  ImageCol, 
  StartPageHeading, 
  StartPageSubHeading, 
  StartPageContentCol, 
  ButtonWrapper, 
  LumenazaLogo,
  StartPageBackgroundGradient
} from 
  '../common/styles/StartPages';
import LumenazaIcon from "../../images/lumenaza_logo_white.svg";

import requireAuth from '../auth/requireAuth';
import {fetchSalespartner} from '../../actions/salespartnerActions';
import { SECONDARY_WHITE_COLOR } from "../../constants/css";
import { POD_NAME } from '../../constants';
import {logout} from '../../actions/authActions';
import InputWrapper from '../common/forms/InputWrapper';

const LogOutWrapper = styled.div`
  position: absolute;
  right: 15%;
  top: 40px;
`

const validate = values => {
    const errors = {}
    if (!values.spid) {
      errors.spid = 'Required'
    }
    return errors
}

export class SalespartnerForm extends React.Component {
  componentDidMount(){
    if (this.props.user.isSalespartnerUser) {
      this.props.history.push("/dashboard");
    }
  }

  onSubmit = values => {
    this.props.getSalespartner(values, this.props.token, spObj => {
      localStorage.setItem("salespartner", JSON.stringify(spObj));
      this.props.history.push("/dashboard");
    });
  };

  generateUserName = () => {
    const firstName = this.props.user.first_name
    const lastName = this.props.user.last_name

    if (firstName) {
      return `${firstName} ${lastName}`
    }

    const userName = this.props.user.username
    const formmattedUserName = userName[0].toUpperCase() + userName.slice(1)

    return formmattedUserName
  }

  render() {
    const { handleSubmit} = this.props;
    const { error } = this.props.salespartner;
    return (
      <Container fluid>
        <StartPageBackgroundGradient>
          <ImageCol lg={5}>
            <LumenazaLogo src={LumenazaIcon} />
          </ImageCol>
          <StartPageContentCol lg={7}>
            <LogOutWrapper>
              <LogOutButton logoutHandler={this.props.logoutDispatcher} isSalespartnerForm={this.props.isSalespartnerForm} />
            </LogOutWrapper>
            <Container>
              <Row className="justify-content-center">
                <Col lg={9}>
                  <StartPageHeading>{POD_NAME}</StartPageHeading>
                  <StartPageSubHeading>
                    Willkommmen {this.generateUserName()}!<br/> Bitte wähle den Salespartner aus
                  </StartPageSubHeading>
                  <Form onSubmit={handleSubmit(this.onSubmit)}>
                    <Field
                      name="spid"
                      component={InputWrapper}
                      type="text"
                      labelText="Salespartner"
                      colorLabel={SECONDARY_WHITE_COLOR}
                      opacityLabelSmall="0.8"
                      colorBorder={SECONDARY_WHITE_COLOR}
                      colorText={SECONDARY_WHITE_COLOR}
                      className="mb-4 pb-2"
                      serverError={error.message}
                      autoComplete="off"
                    />
                    <ButtonWrapper>
                      <Button
                        type="submit"
                        color={SECONDARY_WHITE_COLOR}
                        colorText={SECONDARY_WHITE_COLOR}
                      >
                        anmelden
                      </Button>
                    </ButtonWrapper>
                  </Form>
                </Col>
              </Row>
            </Container>
          </StartPageContentCol>
        </StartPageBackgroundGradient>
      </Container>
    );
  }
}

const mapDispatchToProps = function(dispatch) {
    return {
      getSalespartner: (formData, token, callbackSuccess) => {
        return dispatch(fetchSalespartner(formData, token, callbackSuccess)); // Notice the return
      },
      logoutDispatcher: (postData, token, callback) => {
        dispatch(logout(postData, token, callback));
      }
    };
  };

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  reduxForm({ form: 'salespartner', validate })
)(requireAuth(SalespartnerForm, 'SalesPartner'));
