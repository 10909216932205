import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { NO_ACTIVE_BG_COLOR } from '../../../constants/css';

const LogoImage = styled.img`
  width: 256px;
  border: 1px solid ${NO_ACTIVE_BG_COLOR};
`

const fileTypes = {
    "png" : 'image/png',
    "jpeg" : 'image/jpeg',
    "jpg" : 'image/jpg',
    "svg" : 'image/svg+xml',
    "pdf" : 'application/pdf',
    "woff" : 'application/font-woff',
    "tff" : 'application/font-tff',
};

const LogoImageHandler = ({fileObj, altText}) => {
    const [ fileUrl, setFileUrl ] = useState(null);

    
    const fileType = useMemo(() => { // retuns MIME type - Multipurpose Internet Mail Extensions
        if(!fileObj) return null;
        if (fileObj.type.length > 0) return fileObj.type;
        const extension = fileObj.name.split('.').pop();
        return fileTypes[extension] || "application/octet-stream"; // generic binary file type to avoid crashing if the file type is in the list
    }, [fileObj]);

    useEffect(() => {
        if (!fileObj || !fileType) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const base64Data = e.target.result.split(',')[1];
            const dataUrl = `data:${fileType};base64,${base64Data}`;
            setFileUrl(dataUrl)
        };
        reader.readAsDataURL(fileObj); 
        return () => setFileUrl(null);
    }, [fileObj, fileType]);

    return (
        <>
            {fileUrl ? <LogoImage src={fileUrl} alt={altText}/> : <div>loading</div>}
        </>
    );
};

export default React.memo(LogoImageHandler); // prevents re-rendering if the props haven't changed 