import React from "react";
import AccordionItem from "../../../../../common/lib/Accordion";
import { GRAY_COLOR, NO_ACTIVE_BG_COLOR, NO_ACTIVE_COLOR } from "../../../../../../constants/css";

export default function RlmBaseFee(props) {

    return (
        <>
            <AccordionItem title="KOSTEN PRO MONAT" expanded={true}>
                <AccordionItem
                    title="NETZENTGELTE & MESSSTELLENGEBÜHREN"
                    expanded={true}
                    contentBackground={GRAY_COLOR}
                    barBackgroundClosed={NO_ACTIVE_COLOR}
                    barBackgroundOpen={NO_ACTIVE_BG_COLOR}
                    marginBottom="40px"
                    marginTop="10px"
                >
                    <div><p>Der Netzbetreiber rechnet gegenüber dem Stromlieferant monatlich den Jahresleistungspreis der Netzentgelte und Kosten für den Messstellenbetrieb ab.
                        Die Kosten, die durch den Netzbetreiber in Rechnung gestellt werden, werden 1:1 auf der monatlichen RLM Rechnung an den Endkunden weitergereicht. Alle Kostenpunkte werden in einer Detailansicht transparent aufgelistet.
                    </p>
                    </div>
                </AccordionItem>
                {props.children}
            </AccordionItem>
        </>
    )

}